import React, { useCallback, useState } from 'react'
import jmsLogoSvg from '../../images/jms-logo.svg'
import cn from 'classnames'
import './header.scss'
import { Link } from 'gatsby'
import whatsappIconSvg from '../../images/whatsapp-icon.svg'

const block = 'header'
const Header = ({ currentPath }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleNav = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <header className={block}>
      <div className={`container ${block}__container`}>
        <Link className={`${block}__logo-link`} to="/">
          <img className={`${block}__logo`} src={jmsLogoSvg} />
        </Link>
        <div
          className={cn(`${block}__nav-wrapper`, {
            [`${block}__nav-wrapper--expanded`]: expanded,
          })}
        >
          <nav
            className={cn(`${block}__nav`, {
              [`${block}__nav--expanded`]: expanded,
            })}
          >
            <Link
              className={cn(`${block}__link`, {
                [`${block}__link--active`]: currentPath === '/moedas',
              })}
              to="/moedas"
            >
              Moedas
            </Link>
            <Link
              className={cn(`${block}__link`, {
                [`${block}__link--active`]: currentPath === '/cartoes',
              })}
              to="/cartoes"
            >
              Cartões
            </Link>
            <Link
              className={cn(`${block}__link`, {
                [`${block}__link--active`]: currentPath === '/seguro-viagem',
              })}
              to="/seguro-viagem"
            >
              Seguro Viagem
            </Link>
            <Link
              className={cn(`${block}__link`, {
                [`${block}__link--active`]: currentPath === '/cambio-comercial',
              })}
              to="/cambio-comercial"
            >
              Câmbio Comercial
            </Link>
            <Link
              className={cn(`${block}__link`, {
                [`${block}__link--active`]: currentPath === '/internacional',
              })}
              to="/internacional"
            >
              Internacional
            </Link>
            <Link
              className={cn(`${block}__link`, {
                [`${block}__link--active`]: currentPath === '/chip-viagem',
              })}
              to="/chip-viagem"
            >
              Chip Viagem
            </Link>

            <div className={`${block}__block`}>
              <div className={`${block}__item`}>
                <a
                  target="_blank"
                  className={`${block}__contact-link`}
                  href="https://wa.me/551146100505"
                >
                  WhatsApp
                  <img
                    className={`${block}__whatsapp-icon`}
                    src={whatsappIconSvg}
                  />
                </a>
              </div>
              <div className={`${block}__item`}>
                Telefone: (11)&nbsp;4610-0505
              </div>

              <br />
            </div>
          </nav>

          <button onClick={toggleNav} className={`${block}__mobile-toggle`}>
            <div
              className={cn(`${block}__toggle-line`, {
                [`${block}__toggle-line--expanded`]: expanded,
              })}
            ></div>
            <div
              className={cn(`${block}__toggle-line`, {
                [`${block}__toggle-line--expanded`]: expanded,
              })}
            ></div>
            <div
              className={cn(`${block}__toggle-line`, {
                [`${block}__toggle-line--expanded`]: expanded,
              })}
            ></div>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
