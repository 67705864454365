import React from 'react'
import './selo-abracam.scss'
import seloAbracamPng from '../../images/selo-abracam.png'

const block = 'selo-abracam'
const SeloAbracam = ({ headline }) => {
  return (
    <div className={block}>
      <div className={`container ${block}__container`}>
        <img
          className={`${block}__img`}
          src={seloAbracamPng}
          alt="Selo ABRACAM de conformidade Série 3000"
          title="Selo ABRACAM de conformidade Série 3000"
        />
        <div className={`${block}__text`}>
          <div className={`${block}__headline`}>Selo ABRACAM Série 3000</div>
          <div className={`${block}__description`}>
            O Selo ABRACAM de Conformidade foi concebido pela Associação
            Brasileira de Câmbio com a missão de uniformizar as melhores
            práticas e procedimentos aplicados pelos agentes atuantes no mercado
            cambial, possibilitando assim maior transparência, robustez e
            economia para as instituições e seus clientes.
            <br />
            <br />
            Contando com a expertise adquirida ao longo de mais de 20 anos de
            atuação nesse cenário, a ABRACAM buscou identificar e mitigar os
            maiores riscos e deficiências do segmento, propiciando um ambiente
            mais competitivo e leal à todos os participantes do mercado e à
            sociedade.
            <br />
            <br />
            A Circular nº 3.978/2020 do Banco Central do Brasil foi referência
            fundamental na construção do Selo, de forma a assegurar o integral
            alinhamento às novas diretrizes. O instrumento busca um tratamento
            isonômico aos autorizados, em que se permite uma identificação mais
            fácil, precisa e menos onerosa das estruturas de controles
            existentes.
            <br />
            <br />A relevância da atualização normativa para a prevenção aos
            crimes de lavagem de dinheiro é tamanha que se buscou conciliar o
            lançamento do Selo com a entrada em vigor da Circular, criando assim
            um marco para o combate a essa prática nociva à economia brasileira.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeloAbracam
