import React from 'react'
import './footer.scss'
import jmsLogoSvg from '../../images/jms-logo.svg'
import trevisoWhiteLogoSvg from '../../images/treviso-white-logo.svg'
// import bbLogoPng from '../../images/bb-logo.png'
import bancoDoBrasil from '../../images/banco-do-brasil.svg'
import whatsappIconSvg from '../../images/whatsapp-icon.svg'
import facebookIconSvg from '../../images/facebook-icon.svg'
import instagramIconSvg from '../../images/instagram-icon.svg'

const block = 'footer'
const Footer = () => {
  return (
    <div className={block}>
      <div className={`${block}__container container`}>
        <div className={`${block}__block`}>
          <img
            className={`${block}__jms-logo`}
            src={jmsLogoSvg}
            alt="Logo JMS Câmbio"
            title="JMS Câmbio"
          />
          <div className={`${block}__highlight ${block}__highlight--no-margin`}>
            JMS Serviços Administrativos Ltda
          </div>
          <div className={`${block}__item`}>Cnpj 30.630.954/0001-49</div>
          <div className={`${block}__social`}>
            <a
              target="_blank"
              className={`${block}__social-link`}
              href="https://wa.me/551146100505"
              title="WhatsApp"
            >
              <img className={`${block}__social-icon`} src={whatsappIconSvg} />
            </a>
            <a
              target="_blank"
              className={`${block}__social-link`}
              href="https://www.facebook.com/JMS-C%C3%A2mbio-111863458095450"
              title="Facebook"
            >
              <img className={`${block}__social-icon`} src={facebookIconSvg} />
            </a>
            <a
              target="_blank"
              className={`${block}__social-link`}
              href="https://www.instagram.com/jms.cambio/"
              title="Instagram"
            >
              <img className={`${block}__social-icon`} src={instagramIconSvg} />
            </a>
          </div>
        </div>
        <div className={`${block}__block`}>
          <div className={`${block}__highlight`}>Contato</div>
          <div className={`${block}__item`}>
            <a
              target="_blank"
              className={`${block}__link`}
              href="https://wa.me/551146100505"
            >
              WhatsApp
              <img
                className={`${block}__whatsapp-icon`}
                src={whatsappIconSvg}
              />
            </a>
          </div>
          <div className={`${block}__item`}>Telefone: (11)&nbsp;4610-0505</div>
          <div className={`${block}__item`}>E-mail: jms.cambio@gmail.com</div>
          <br />

          <div className={`${block}__item`}>
            <a
              target="_blank"
              className={`${block}__link`}
              href="http://www.syscambio.com.br:81/websac/auth/index/?token=euzCUDM7%2BCRewxRhlee8Pg%3D%3D&type=1"
            >
              SAC
            </a>
          </div>
          <div className={`${block}__item`}>
            <a
              target="_blank"
              className={`${block}__link`}
              href="http://www.advicetech.com.br/Ouvidoria_front/Paginas/RegistrarAtendimento/registraratendimento.aspx?CD_CHAVE=543CEFBC-282A-4878-9164-342F6496815A"
            >
              Ouvidoria
            </a>
          </div>
          <div className={`${block}__item`}>
            <a
              target="_blank"
              className={`${block}__link`}
              href="http://www.syscambio.com.br:81/WebSac//auth/index/?token=euzCUDM7%2BCRewxRhlee8Pg%3D%3D&type=3"
            >
              Denúncias
            </a>
          </div>
        </div>
        <div className={`${block}__block`}>
          <div className={`${block}__highlight`}>Endereço</div>
          <div className={`${block}__item`}>
            Av. João Manoel, 600 - Loja 07
            <br />
            Center Ville, Arujá - SP
            <br />
            07400-610
          </div>
          <div className={`${block}__item`}>
            <a
              target="_blank"
              className={`${block}__link`}
              href="https://g.page/jmscambio?share"
            >
              Mapa
            </a>
          </div>
        </div>
        <div className={`${block}__block`}>
          <div className={`${block}__highlight`}>Parceiros</div>
          <div className={`${block}__item`}>
            <img
              className={`${block}__treviso-logo`}
              src={trevisoWhiteLogoSvg}
              alt="Logo Treviso Câmbio"
              title="Treviso Câmbio"
            />
            <div className={`${block}__item`}>
              <img
                className={`${block}__bb-logo`}
                src={bancoDoBrasil}
                alt="Logo Banco do Brasil"
                title="Banco do Brasil"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
